import "/root/workspace/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/node_modules/core-js/modules/es.promise.finally.js";
import './setenv-admin';
import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload'; // import 'normalize.css/normalize.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n

import '@/styles/index.scss'; // global css

import i18n from '@/i18n/index';
import echarts from 'echarts';
import App from './App.vue';
import store from './store';
import router from './router';
import '@/icons'; // icon

import '@/permission'; // permission control
// Vue.use(ElementUI, { locale });

Vue.use(ElementUI);
Vue.use(VueLazyLoad);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
var vm = new Vue({
  i18n: i18n,
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
window.vue = vm;